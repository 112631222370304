import http from "@/http";
import eventBus from "@/events";
import Constants from "@/constants";

const Quotes = {
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'number') + sortDesc;

        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, sort};
        if (size) {
            pagination.size = size;
        }
        const params = {...filter, ...pagination};
        const resp = await http("/quotes/search/all", {params});
        eventBus.$emit(Constants.EVENT_ON_PROFILE_LIST, resp.data.page.totalElements);
        return resp.data._embedded.quotes;
    },
    create: function (data) {
        return http.post('/quotes', data);
    },
    update: function (id, data) {
        return http.patch('/quotes/' + id, data);
    },
    findById(id) {
        return http.get('/quotes/' + id);
    },
    enable(id) {
        return http.patch('/quotes/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/quotes/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/quotes/' + id)
    }
};

export default Quotes;
