<template>
  <div>
    <div v-if="hasCompany">
      <div v-if="loadingQuoteData" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('QUOTE')})  | capitalize }}</strong>
      </div>
      <div v-else>
        <b-card bg-variant="dark" text-variant="light" :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize"
                class="mb-2">
          <b-card-text>
            <pre v-if="debug">{{ form }}</pre>
            <b-form @submit.prevent="save">
              <!-- date input -->
              <b-form-group
                  id="date-form-group"
                  :label="$t('QUOTE_DATE_LABEL') | capitalize"
                  label-for="date-input"
                  :state="state('date')"
              >
                <b-datepicker id="date-input" v-model="$v.form.date.$model" :state="state('date')"
                              trim></b-datepicker>

                <b-form-invalid-feedback id="date-input-feedback">
                  <div v-for="error in errors('date')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DATE_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- date input -->

              <!-- attention input -->
              <b-form-group
                  id="attention-form-group"
                  :label="$t('QUOTE_ATTENTION_LABEL') | capitalize"
                  label-for="attention-input"
                  :state="state('attention')">
                <b-input id="attention-input" v-model="$v.form.attention.$model" :state="state('attention')"
                         trim></b-input>

                <b-form-invalid-feedback id="attention-input-feedback">
                  <div v-for="error in errors('attention')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_ATTENTION_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- attention input -->

              <!-- customer input -->
              <div v-if="loadingCustomers" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('CUSTOMERS')})  | capitalize }}</strong>
              </div>

              <b-form-group
                  v-else
                  id="customer-form-group"
                  :label="$t('QUOTE_CUSTOMER_LABEL') | capitalize"
                  label-for="customer-input"
                  :state="state('customer')">
                <b-select id="customer-input" @change="setCustomerCode" v-model="$v.form.customer.$model"
                          :state="state('customer')" trim>
                  <b-select-option v-for="customer in customers" :value="customer.crazonsocial"
                                   :key="customer.cidclienteproveedor">{{ customer.crazonsocial }}
                  </b-select-option>
                </b-select>

                <b-form-invalid-feedback id="customer-input-feedback">
                  <div v-for="error in errors('customer')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_CUSTOMER_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- customer input -->

              <!-- projectName input -->
              <div v-if="loadingProjects" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PROJECTS')})  | capitalize }}</strong>
              </div>

              <b-form-group
                  v-else
                  id="project-form-group"
                  :label="$t('QUOTE_PROJECT_NAME_LABEL') | capitalize"
                  label-for="project-input"
                  :state="state('projectName')">
                <b-select id="project-input" @change="setProjectCode" v-model="$v.form.projectName.$model"
                          :state="state('projectName')" trim>
                  <b-select-option v-for="project in projects" :value="project.cnombreproyecto"
                                   :key="project.cidproyecto">{{ project.cnombreproyecto }}
                  </b-select-option>
                </b-select>

                <b-form-invalid-feedback id="project-input-feedback">
                  <div v-for="error in errors('projectName')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_PROJECT_NAME_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- projectName input -->

              <!-- currency input -->
              <b-form-group
                  id="currency-form-group"
                  :label="$t('QUOTE_CURRENCY_LABEL') | capitalize"
                  label-for="currency-input"
                  :state="state('currency')">
                <b-select id="currency-input" v-model="$v.form.currency.$model" :state="state('currency')" trim>
                  <b-select-option value="MXN">{{ $t('MXN_LABEL') | capitalize }}</b-select-option>
                  <b-select-option value="USD">{{ $t('USD_LABEL') | capitalize }}</b-select-option>
                </b-select>

                <b-form-invalid-feedback id="currency-input-feedback">
                  <div v-for="error in errors('currency')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_CURRENCY_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- currency input -->

              <!-- exchangeRate input -->
              <b-form-group
                  id="exchange-rate-form-group"
                  :label="$t('QUOTE_EXCHANGE_RATE_LABEL') | capitalize"
                  label-for="exchange-rate-input"
                  :state="state('exchangeRate')">
                <b-input id="exchange-rate-input" v-model="$v.form.exchangeRate.$model" :state="state('exchangeRate')"
                         trim></b-input>

                <b-form-invalid-feedback id="exchange-rate-input-feedback">
                  <div v-for="error in errors('exchangeRate')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_EXCHANGE_RATE_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- exchangeRate input -->

              <b-button type="submit" :disabled="saving" variant="primary">
                <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
                <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
                <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
              </b-button>
            </b-form>
          </b-card-text>
        </b-card>

        <b-card bg-variant="dark" text-variant="light" class="my-1" :title="$t('QUOTE_DETAILS_TITLE') | capitalize">
          <b-card-text>
            <div class="text-right">
              <b-button @click="showNewDetailForm()" variant="primary">
                <b-icon-plus-circle></b-icon-plus-circle>
              </b-button>
            </div>
            <div class="mt-2" v-if="form.details && form.details.length > 0">
              <b-table-simple table-variant="dark" hover>
                <b-thead>
                  <b-tr>
                    <b-th>#</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_DESCRIPTION_LABEL') | capitalize }}</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_QUANTITY_LABEL')  | capitalize }}</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_UNITARY_PRICE_LABEL')  | capitalize }}</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_SUBTOTAL_LABEL')  | capitalize }}</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_PERCENTAGE_IVA_LABEL') | capitalize }}</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_TOTAL_LABEL') | capitalize }}</b-th>
                    <b-th>{{ $t('QUOTE_DETAIL_OPTIONS_LABEL') | capitalize }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(detail, idx) in form.details" :key="detail.id">
                    <b-td>{{ idx + 1}}</b-td>
                    <b-td>{{ detail.description }}</b-td>
                    <b-td>{{ detail.quantity }}</b-td>
                    <b-td>{{ detail.unitaryPrice }}</b-td>
                    <b-td>{{ detail.subtotal }}</b-td>
                    <b-td>{{ detail.percentageIva }}</b-td>
                    <b-td>{{ detail.total }}</b-td>
                    <b-td>
                      <b-button class="mr-1" @click="editDetail(detail)" variant="primary">
                        <b-icon-pencil></b-icon-pencil>
                      </b-button>
                      <b-button @click="deleteDetail(idx)" variant="danger">
                        <b-icon-x-circle></b-icon-x-circle>
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <div class="text-center" v-else>
              <h1>{{ $t('QUOTES_MUST_ADD_DETAIL') | capitalize }}</h1>
            </div>
          </b-card-text>
        </b-card>

        <b-modal id="detail-modal" :title="$t('ADD_DETAIL') | capitalize" @ok="addDetail">
          <b-form>
            <b-form-group :label="$t('QUOTE_DETAIL_PRODUCT_CODE_LABEL') | capitalize">
              <b-input :state="state2($v.detail, 'productCode')" v-model="$v.detail.productCode.$model"></b-input>

              <b-form-invalid-feedback id="product-code-input-feedback">
                <div v-for="error in errors2($v.detail, 'productCode')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DETAIL_PRODUCT_CODE_LABEL')},}) | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('QUOTE_DETAIL_DESCRIPTION_LABEL') | capitalize">
              <b-form-textarea :state="state2($v.detail, 'description')"
                               v-model="$v.detail.description.$model"></b-form-textarea>

              <b-form-invalid-feedback id="description-input-feedback">
                <div v-for="error in errors2($v.detail, 'description')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DETAIL_DESCRIPTION_LABEL')},}) | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('QUOTE_DETAIL_MEASURE_UNIT_LABEL') | capitalize">
              <b-input :state="state2($v.detail, 'measureUnit')" v-model="$v.detail.measureUnit.$model"></b-input>

              <b-form-invalid-feedback id="product-code-input-feedback">
                <div v-for="error in errors2($v.detail, 'measureUnit')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DETAIL_MEASURE_UNIT_LABEL')},}) | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('QUOTE_DETAIL_QUANTITY_LABEL') | capitalize">
              <b-input :state="state2($v.detail, 'quantity')" v-model="$v.detail.quantity.$model"
                       @blur="formatNumber('quantity', '0,0.00')"></b-input>

              <b-form-invalid-feedback id="product-code-input-feedback">
                <div v-for="error in errors2($v.detail, 'quantity')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DETAIL_QUANTITY_LABEL')},}) | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('QUOTE_DETAIL_UNITARY_PRICE_LABEL') | capitalize">
              <b-input :state="state2($v.detail, 'unitaryPrice')" v-model="$v.detail.unitaryPrice.$model"
                       @blur="formatNumber('unitaryPrice', '$0,0.00')"></b-input>

              <b-form-invalid-feedback id="product-code-input-feedback">
                <div v-for="error in errors2($v.detail, 'unitaryPrice')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DETAIL_UNITARY_PRICE_LABEL')},}) | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('QUOTE_DETAIL_PERCENTAGE_IVA_LABEL') | capitalize">
              <b-input :state="state2($v.detail, 'percentageIva')" v-model="$v.detail.percentageIva.$model"
                       @blur="formatPercentage('percentageIva', '0%')"></b-input>

              <b-form-invalid-feedback id="product-code-input-feedback">
                <div v-for="error in errors2($v.detail, 'percentageIva')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('QUOTE_DETAIL_PERCENTAGE_IVA_LABEL')},}) | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </b-modal>
      </div>
    </div>
    <div v-else>
      {{ $t('NO_COMPANY_SELECTED') }}
    </div>
  </div>
</template>

<script>
import {Company, Form} from "@/mixins";
import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
import Quotes from "@/quotes";
import Numeral from 'numeral';
import {v4} from 'uuid';
import Projects from "@/projects";
import Customers from "@/customers";
import eventBus from "@/events";
import Constants from "@/constants";

export default {
  name: "QuoteForm",
  components: {},
  mixins: [Form, Company],
  async mounted() {
    if (this.hasCompany) {
      this.loadProjects().then();
      this.loadCustomers().then();
    }

    eventBus.$on('COMPANY_CHANGED', (company) => {
      this.currentCompany$ = company;
      this.loadProjects().then();
      this.loadCustomers().then();
    });
  },
  methods: {
    beforeSave(form) {
      form.$touch();
      if (form.$invalid) {
        if (Constants.DEBUG) {
          console.log(form);
        }
        throw new Error('Form is invalid');
      }
      form.details.$model.map((x, idx) => {
        x.id = undefined;
        x.index = idx;
        x.quantity = Numeral(x.quantity).value();
        x.unitaryPrice = Numeral(x.unitaryPrice).value();
        x.percentageIva = Numeral(x.percentageIva).value();
        x.subtotal = undefined;
        x.iva = undefined;
        x.total = undefined;
      });
    },
    setCustomerCode(ev) {
      const idx = this.customers.map(x => x.crazonsocial).indexOf(ev);
      if (idx > -1) {
        this.$v.form.customerCode.$model = this.customers[idx].ccodigocliente;
      }
    },
    setProjectCode(ev) {
      const idx = this.projects.map(x => x.cnombreproyecto).indexOf(ev);
      if (idx > -1) {
        this.$v.form.projectCode.$model = this.projects[idx].ccodigoproyecto;
      }
    },
    async loadProjects() {
      try {
        this.loadingProjects = true;
        this.projects = await Projects.findAll({perPage: 100000, sortBy: 'CNOMBREPROYECTO'});
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingProjects = false;
      }
    },
    async loadCustomers() {
      try {
        this.loadingCustomers = true;
        this.customers = await Customers.findAll({perPage: 100000, sortBy: 'CRAZONSOCIAL'});
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCustomers = false;
      }
    },
    editDetail(detail) {
      this.detail = detail;
      this.showNewDetailForm();
    },
    deleteDetail(idx) {
      this.form.details.splice(idx, 1);
    },
    formatNumber(field, format) {
      this.detail[field] = Numeral(this.detail[field]).format(format);
    },
    formatPercentage(field, format) {
      this.detail[field] = Numeral(this.detail[field] / 100).format(format);
    },
    addDetail(ev) {
      this.$v.detail.$touch();
      if (this.$v.detail.$invalid) {
        ev.preventDefault();
      }
      this.$v.detail.$reset();
      const idx = this.form.details.map(x => x.id).indexOf(this.detail.id);
      if (idx === -1) {
        this.form.details.push(this.detail);
      } else {
        this.form.details[idx] = this.detail;
      }
      this.detail = {
        id: v4(),
        index: 0,
        description: null,
        productCode: null,
        measureUnit: null,
        quantity: null,
        unitaryPrice: null,
        percentageIva: null,
        get subtotal() {
          return Numeral(Numeral(this.quantity).value() * Numeral(this.unitaryPrice).value()).format('$0,0.00');
        },
        get iva() {
          return Numeral(Numeral(this.subtotal).value() * (Numeral(this.percentageIva).value())).format('$0,0.00');
        },
        get total() {
          return Numeral(Numeral(this.subtotal).value() + Numeral(this.iva).value()).format('$0,0.00');
        }
      };
    },
    showNewDetailForm() {
      this.$bvModal.show('detail-modal')
    },
    getData() {
      this.loadingQuoteData = true;
      Quotes.findById(this.id)
          .then(resp => {
            this.form.date = resp.data.date;
            this.form.attention = resp.data.attention;
            this.form.customer = resp.data.customer;
            this.form.projectName = resp.data.projectName;
            this.form.customerCode = resp.data.customerCode;
            this.form.projectCode = resp.data.projectCode;
            this.form.currency = resp.data.currency;

            this.form.details = resp.data.details.sort((a, b) => a > b ? 1 : -1).map(detail => {
              return {
                id: v4(),
                index: 0,
                description: detail.description,
                productCode: detail.productCode,
                measureUnit: detail.measureUnit,
                quantity: Numeral(detail.quantity).format('0,0.00'),
                unitaryPrice: Numeral(detail.unitaryPrice).format('$0,0.00'),
                percentageIva: Numeral(detail.percentageIva).format("0%"),
                get subtotal() {
                  return Numeral(Numeral(this.quantity).value() * Numeral(this.unitaryPrice).value()).format('$0,0.00');
                },
                get iva() {
                  return Numeral(Numeral(this.subtotal).value() * (Numeral(this.percentageIva).value())).format('$0,0.00');
                },
                get total() {
                  return Numeral(Numeral(this.subtotal).value() + Numeral(this.iva).value()).format('$0,0.00');
                }
              }
            });

            this.form.exchangeRate = resp.data.exchangeRate;
          })
          .catch(e => {
            console.error(e);
            this.sendError('ERROR_LOADING_QUOTE_DATA', e);
          })
          .finally(() => {
            this.loadingQuoteData = false;
          });
    }
  },
  data() {
    return {
      originals: {
        name: undefined
      },
      loadingCustomers: false,
      loadingProjects: false,
      customers: [],
      projects: [],
      form: {
        date: new Date(),
        attention: null,
        customer: undefined,
        projectName: undefined,
        customerCode: null,
        projectCode: null,
        currency: 'MXN',
        details: [],
        exchangeRate: 1,
      },
      detail: {
        id: v4(),
        description: null,
        productCode: null,
        measureUnit: null,
        quantity: null,
        unitaryPrice: null,
        percentageIva: null,
        get subtotal() {
          return Numeral(Numeral(this.quantity).value() * Numeral(this.unitaryPrice).value()).format('$0,0.00');
        },
        get iva() {
          return Numeral(Numeral(this.subtotal).value() * (Numeral(this.percentageIva).value())).format('$0,0.00');
        },
        get total() {
          return Numeral(Numeral(this.subtotal).value() + Numeral(this.iva).value()).format('$0,0.00');
        }
      },
      permissions: [],
      loadingQuoteData: false,
      loadingQuotePermissions: false,
      editPage: 'QUOTE_EDIT_TITLE'
    }
  },
  computed: {
    controller() {
      return Quotes;
    }
  },
  validations: {
    form: {
      date: {
        required
      },
      attention: {},
      customer: {
        required
      },
      customerCode: {
        required
      },
      projectCode: {
        required
      },
      projectName: {
        required
      },
      currency: {
        required, minLength: minLength(3), maxLength: maxLength(3)
      },
      exchangeRate: {
        required, minValue: minValue(1)
      },
      details: {
        required, minLength: minLength(1), $each: {
          productCode: {required},
          description: {required},
          measureUnit: {required},
          quantity: {required},
          unitaryPrice: {required},
          percentageIva: {required},
        }
      }
    },
    detail: {
      productCode: {},
      description: {required},
      measureUnit: {required},
      quantity: {required, decimal: (val) => Numeral(val).value() !== null && Numeral(val).value() > 0},
      unitaryPrice: {required, decimal: (val) => Numeral(val).value() !== null && Numeral(val).value() > 0},
      percentageIva: {required, decimal: (val) => Numeral(val).value() !== null && Numeral(val).value() > 0},
    }
  }
}
</script>

<style scoped>

</style>
