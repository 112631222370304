import http from "@/http";
import eventBus from "@/events";
import Constants from "@/constants";
import Companies from "@/companies";

const Projects = {
    findAll: async function (ctx) {
        const database = Companies.currentCompany;
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'CCODIGOPROYECTO') + sortDesc;

        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, sort};
        if (size) {
            pagination.size = size;
        }
        const params = {...filter, ...pagination};
        const resp = await http("/companies/" + database + "/projects", {params});
        eventBus.$emit(Constants.EVENT_ON_PROFILE_LIST, resp.data.totalElements);
        return resp.data.content;
    }
};

export default Projects;
